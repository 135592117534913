import React, { useState, useEffect, useRef } from "react";
import logo from "../assests/images/logo.png";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-scroll";

const Navbar = () => {
  const [toggleLinks, setToggleLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (toggleLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = "0px";
    }
  }, [toggleLinks]);

  const toggle = () => {
     setToggleLinks(!toggleLinks)
  }

  return (
    <nav className="w-full fixed z-[999] top-0 bg-white dark:bg-[#0a192f] md:drop-shadow md:shadow-lg">
      <div className="md:flex md:items-center md:justify-around px-6 md:px-20 py-2 ">
        <div className="flex items-center justify-between md:w-1/2">
          <Link to="home" spy={true} smooth={true} duration={300} offset={-100}>
            <img className="w-14 h-14" src={logo} alt="logo" />
          </Link>

          <button
            className="md:hidden"
            onClick={toggle}
          >
            {toggleLinks ? (
              <AiOutlineClose size="1.5rem" color="#4B6CC1" />
            ) : (
              <FaBars size="1.5rem" color="#4B6CC1" />
            )}
          </button>
        </div>
        <div
          ref={linksContainerRef}
          className="text-center bg-transparent md:bg-none md:w-1/2 text-[#4B6CC1] dark:text-white uppercase transition delay-500 ease-in-out h-0 overflow-hidden md:!h-auto "
        >
          <ul
            ref={linksRef}
            className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between md:gap-2 pt-4 pb-2 md:p-4 "
          >
            <li className="cursor-pointer pb-1 bg-gradient-to-r from-purple-500 to-pink-500 bg-no-repeat bg-bottom  transition-all duration-300 ease-out delay-250  bg-[length:0px_5px,auto] hover:bg-[length:10%_4px,auto]  md:hover:bg-[length:100%_4px,auto] ">
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={300}
                offset={-100}
              >
                home
              </Link>
            </li>
            <li className="cursor-pointer pb-1 bg-gradient-to-r from-purple-500 to-pink-500 bg-no-repeat bg-bottom  transition-all duration-300 ease-out delay-250  bg-[length:0px_5px,auto] hover:bg-[length:10%_4px,auto]  md:hover:bg-[length:100%_4px,auto]">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                duration={300}
                offset={-100}
              >
                about
              </Link>
            </li>
            <li className="cursor-pointer pb-1 bg-gradient-to-r from-purple-500 to-pink-500 bg-no-repeat bg-bottom  transition-all duration-300 ease-out delay-250  bg-[length:0px_5px,auto] hover:bg-[length:15%_4px,auto]  md:hover:bg-[length:100%_4px,auto]">
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                duration={300}
                offset={-80}
              >
                projects
              </Link>
            </li>
            <li className="cursor-pointer pb-1 bg-gradient-to-r from-purple-500 to-pink-500 bg-no-repeat bg-bottom  transition-all duration-300 ease-out delay-250  bg-[length:0px_5px,auto] hover:bg-[length:15%_4px,auto]  md:hover:bg-[length:100%_4px,auto]">
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={300}
              >
                Contacts
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
