import React from "react";
import { projects } from "../assests/data";
import Card from "../components/Card";

const Projects = () => {
  return (
    <div
      id="projects"
      className="px-6 md:px-20 py-10 flex flex-col md:items-center"
    >
      <h2 className="py-6">Projects</h2>

      <div className="md:grid md:grid-cols-2 md:gap-12">
        {projects.map((project) => (
          <Card key={project.id} {...project}/>
        ))}
      </div>
    </div>
  );
};

export default Projects;
