export const projects = [
  {
    id: 1,
    name: "Weather App",
    link: "https://github.com/Shivakc7/Weather-Forecast",
    live: "https://shivakc7.github.io/Weather-Forecast/",
    summary: `A simple React Application for searching weather report of different cities. Built with Open Weather API`,
    tech1: "React",
    tech2: 'TailwindCSS',
    tech3: 'OpenWeather API'
  },
  {
    id: 2,
    name: "Todo App",
    link: "https://github.com/Shivakc7/Todo-App",
    live: "https://shivakc7.github.io/Todo-App/",
    summary: "A Simple Todo List Application to Add, View, Edit and Delete in a Single webpage using React.js .",
    tech1: "React",
    tech2: "CSS",
  },
  {
    id: 3,
    name: "Coming Soon",
    link: " ",
    live: "",
    summary: "",
  },
  
];
