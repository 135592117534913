import React from "react";
import html from "../assests/images/html.svg";
import css from "../assests/images/css.svg";
import tailwind from "../assests/images/tailwind.svg";
import js from "../assests/images/js.svg";
import react from "../assests/images/react.svg";
import npm from "../assests/images/npm.svg";
import redux from "../assests/images/redux.png";

const About = () => {
  return (
    <div
      id="about"
      className="px-6 md:px-20 md:py-4 flex flex-col md:flex-row gap-y-6"
    >
      <div className="md:flex-1">
        <h2 className="pb-10">About me</h2>

        <p className="text-lg text-slate-500 space-y-2">
          I'm a Front-end developer based on Chitwan, who specializes in javascript technologies.
          It's been more than a year, I have been working as a Front-end
          Developer mainly on React. Currently I am working as a freelancing web
          developer.
        </p>
        <p className="text-lg text-slate-500 py-2">
          I have a good understanding of web standards and best practices. I am
          passionate about creating websites that are user friendly and visually
          appealing.I love writing clean readable code that's easy to maintain
          over time. As a matter of fact, I constantly work on improving myself
          seeking to achieve the best version of myself.
        </p>
        <p className="text-lg text-slate-500 space-y-2">
          I build websites using HTML, CSS, JavaScript, and REACT libraries.And also I'm pursuing Bachelors in Information and Communication Technology.
        </p>
      </div>
      <div className="md:flex-1 ">
        <div className="md:pl-8">
          <h2 className="pb-10">Skills</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 items-center gap-6 md:gap-y-12 md:gap-x-6 text-slate-500 ">
            <div className="  space-x-2">
              <img src={html} className="inline h-10 w-10" alt="" />

              <span className="text-base md:text-lg">HTML</span>
            </div>
            <div className="space-x-2">
              <img src={css} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">CSS</span>
            </div>
            <div className="space-x-2">
              <img src={tailwind} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">Tailwind</span>
            </div>
            <div className="space-x-2">
              <img src={js} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">JavaScript</span>
            </div>
            <div className="space-x-2">
              <img src={react} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">React</span>
            </div>
            <div className="space-x-2">
              <img src={redux} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">Redux</span>
            </div>
            <div className="space-x-2">
              <img src={npm} className="inline h-10 w-10" alt="" />
              <span className="text-base md:text-lg">NPM</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
