import React, { useEffect, useState } from "react";

const Home = () => {
  const intro = "I'm Front End Developer";
  const [blink, setBlink] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBlink(intro.slice(0, blink.length + 1));
    }, 150);
    return () => clearTimeout(timeout);
  }, [intro, blink]);

  return (
    <section
      id="home"
      className=" w-full h-[50vh] md:h-[100vh] flex items-center justify-center "
    >
      <div className="">
        <div className="text-center  mb-6">
          <span className="font-fingerPaint text-2xl md:text-5xl text-blue-500 ">
            {" "}
            Hi,👋 I'm Shiva{" "}
          </span>
          <p className="text-slate-500 blinking-cursor pt-6 text-base md:text-2xl">
            {blink}
          </p>
        </div>
        <div className="pt-6 flex justify-start  gap-5 md:gap-10 text-sm md:text-xl">
          <a
            href="https://drive.google.com/file/d/1wVgNBxS_J8PLJKDyiide9dBMTiDBZ0HD/view?usp=share_link"
            className="btn"
            target="_blank"
            rel="noreferrer"
          >
            My Resume
          </a>

          <a href="mailto:shivacr77@gmail.com" className="btn">
            Get In Touch
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
