import React from "react";
import { ImLocation2, ImPhone } from "react-icons/im";
import { MdMailOutline } from "react-icons/md";

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  return (
    <section id="contact">
      <h2>Contact ME</h2>
      <div className="py-10 px-6 md:px-20 md:flex  md:flex-row md:items-start md:justify-between md:gap-4 text-slate-500">
        <div className="md:flex-[1] ">
          <div className="mb-10 md:mb-14">
            <h3 className="text-3xl md:py-6 "> Let's connect,</h3>
            <p className="text-lg">
              Do you want to build a website? Contact me.
            </p>
          </div>
          <div className="text-lg ">
            <div className="">
              <span className="pr-2">
                <ImLocation2 className="inline text-2xl text-blue-500" />{" "}
              </span>
              <span>Bharatpur-7, Chitwan, Nepal</span>
            </div>
            <div className="py-4">
              <span className="pr-2">
                <MdMailOutline className="inline text-2xl text-blue-500" />{" "}
              </span>
              <span>shivacr77@gmail.com</span>
            </div>
            <div>
              <span className="pr-2">
                <ImPhone className="inline text-2xl text-blue-500" />{" "}
              </span>
              <span>+977 9865034683</span>
            </div>
          </div>
        </div>
        <div className="md:flex-[1] py-6">
          <form className="" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="md:text-lg" htmlFor="name">
                Full Name
              </label>
              <input
                className="border-2 border-solid border-gray-600 rounded-lg w-full py-2 px-3 text-gray-800 focus:ring-blue-500 focus:border-indigo-800 focus:outline-none dark:focus:border-transparent dark:bg-gray-300"
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="md:text-lg" htmlFor="email">
                Email
              </label>
              <input
                className="border-2 border-solid border-gray-600 rounded-lg w-full py-2 px-3 text-gray-800 focus:ring-blue-500 focus:border-indigo-800  dark:focus:border-transparent focus:outline-none dark:bg-gray-300"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email"
              />
            </div>
            <div className="mb-4">
              <label className="md:text-lg" htmlFor="name">
                Message
              </label>
              <textarea
                className="w-full resize-none md:resize-y h-32 border-2 border-solid border-gray-600 rounded-lg p-2 md:p-3 text-gray-800 focus:ring-blue-500  focus:border-indigo-800 dark:focus:border-transparent focus:outline-none dark:bg-gray-300"
                type="message"
                name="message"
                id="message"
              />
            </div>
            <button className="btn">Send Message</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
