import React from "react";
import { FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa";

const footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const day = date.getDay();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div className="px-0 md:px-10 bg-[#112240] dark:bg-transparent md:h-12 w-full py-3 flex flex-col-reverse md:flex-row justify-between items-center text-white dark:text-slate-300">
      <div>
        <p className="text-sm md:text-lg">
          <span className="font-fingerPaint ">Shiva KC </span>
          &nbsp;
          <span>
            &copy; {year}.&nbsp;&nbsp;  Have a good {days[day]} !
          </span>
        </p>
      </div>
      <div className="flex gap-x-6 sm:pb-3">
        <div>
          <a href="https://twitter.com/" target="_blank" rel="noreferrer">
            <FaTwitter
              className="text-2xl md:text-2xl hover:text-[#00ACEE] ease-in-out duration-400
          "
            />
          </a>
        </div>
        <div>
          <a
            href="https://linkedin.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn className="text-2xl md:text-2xl hover:text-[#00ACEE] ease-in-out duration-400 " />
          </a>
        </div>
        <div>
          <a
            href="https://github.com/Shivakc7"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="text-2xl md:text-2xl hover:text-[#00ACEE] ease-in-out duration-400" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default footer;
