import React from "react";
import { FaGithub, FaEye } from "react-icons/fa";
import { AiOutlineFolder } from "react-icons/ai";

const Card = ({ id, name, summary, live, tech1, tech2, tech3, link }) => {
  return (
    <div>
      <div
        className="flex flex-col justify-between h-72 shadow-lg  hover:shadow-xl rounded-lg  my-4 md:my-2 p-4 md:space-y-10 text-slate-500 transform transition duration-500 md:hover:scale-105 bg-gray-100 dark:bg-[#112240]  "
        key={id}
      >
        <div>
          <div className="flex items-center justify-between pb-3">
            <AiOutlineFolder className="w-10 h-10 text-blue-500" />
            <div className="flex gap-4">
              <a
                className=""
                href={live}
                target="_blank"
                rel="noreferrer"
                title="live demo"
              >
                <FaEye className=" inline-block w-6 h-6 hover:text-blue-500 " />
              </a>
              <a
                className=""
                href={link}
                target="_blank"
                rel="noreferrer"
                title="Source Code"
              >
                <FaGithub className=" inline-block w-6 h-6 hover:text-blue-500 " />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-xl dark:text-slate-300 capitalize pb-2">{name}</h3>
            <p className="pb-4 leading-7">{summary}</p>
          </div>
        </div>
        <div className="capitalize ">
          <span className="pr-4">{tech1}</span>
          <span className="pr-4">{tech2}</span>
          <span className="pr-4">{tech3}</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
