import React, { useState, useEffect } from "react";
import Navbar from "./components/navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Footer from "./components/footer";
import { BsFillMoonFill, BsSun } from "react-icons/bs";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div className="relative dark:bg-[#0a192f] ">
      <button
        className="fixed top-[42rem] md:top-[90vh] right-8 z-50"
        type="button"
        onClick={toggleTheme}
      >
        {theme === "dark" ? (
          <BsSun className="text-white h-6 w-6" />
        ) : (
          <BsFillMoonFill className="text-slate-600 h-6 w-6" />
        )}
      </button>
      <Navbar />
      <Home />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
